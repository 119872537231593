/* 
body::-webkit-scrollbar{
  display: none;
}

body::-webkit-scrollbar-track{
  display: none;
}

body::-webkit-scrollbar-track-piece{
  display: none;
}

.loader-page{
    background-color: #fff;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

.loader {
    position: absolute;
    top: 40%;
    left: 40%;
  }
  
  .outer,
  .middle,
  .inner {
    border: 15px solid transparent;
    border-top-color: #2b55c7;
    border-right-color: #fa7c14;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  
  .outer {
    width: 23.5em;
    height: 23.5em;
    margin-left: -1.75em;
    margin-top: -1.75em;
    animation: spin 2s linear infinite;
  }
  
  .middle {
    width: 22.1em;
    height: 22.1em;
    margin-left: -1.05em;
    margin-top: -1.05em;
    animation: spin 1.75s linear reverse infinite;
  }
  
  .inner {
    width: 20.8em;
    height: 20.8em;
    margin-left: -0.4em;
    margin-top: -0.4em;
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  } */

  .loader-page{
    background-color: #fff;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

  .h2loader{
    font-size: 54px;
    color: #444;
    text-align: center;
    min-width: 100%;
    margin-top: 3%!important;
    font-weight: 900;
    z-index: 1;

}
  .loader {
    width: 508px;
    height: 508px;
    position: relative;
    background-color: #FFF;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .loader::before , .loader::after{
    content: '';
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50% , -50%);
    width: 68em;
    height: 68em;
    background-image:
      radial-gradient(circle 15px, #ec8208 100%, transparent 0),
      radial-gradient(circle 15px, #ec8208 100%, transparent 0),
      radial-gradient(circle 15px, #ec8208 100%, transparent 0),
      radial-gradient(circle 15px, #ec8208 100%, transparent 0),
      radial-gradient(circle 15px, #ec8208 100%, transparent 0),
      radial-gradient(circle 15px, #ec8208 100%, transparent 0),
      radial-gradient(circle 15px, #ec8208 100%, transparent 0),
      radial-gradient(circle 15px, #ec8208 100%, transparent 0);
    background-position: 2em -20em, 2em 20em, 20em 2em, -20em 2em,
                         15em -15em, -15em -15em, 15em 15em, -15em 15em;
      background-repeat: no-repeat;
    font-size: 1.5px;
    border-radius: 50%;
    animation: blast 1s ease-in infinite;
  }
  .loader::after {
    font-size: 1px;
    background: #0348a3;
    animation: bounce 1s ease-in infinite;
  }
  
  @keyframes bounce {
    0% , 100%{ font-size: 0.75px }
    50% { font-size: 1.5px }
  }
  @keyframes blast {
    0% , 40% {
      font-size: 0.5px;
    }
    70% {
      opacity: 1;
      font-size: 4px;
    }
     100% {
       font-size: 6px;
      opacity: 0;
    }
  }